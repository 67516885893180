<template>
  <Unauthenticated>
    <div class="top-margin">

      <form v-on:submit.prevent="login">
        <img src="/beamylogo_white.svg" />
        <h2>Choose Site:</h2>
        <div class="error" v-if="errors && errors.all">{{ errors.all }}</div>

        <div :key="site" v-for="site in sites">
          <button @click="choose(site)" type="submit" value="Log In" class="w-100 title">{{ site }}</button>
        </div>

        <a class="other" @click="signOut">Sign out</a>

      </form>
    </div>
  </Unauthenticated>
</template>

<script>
import Unauthenticated from '@/components/_layouts/Unauthenticated'
// import {api} from "@/api";

export default {
  name: "ChooseStore",
  components: { Unauthenticated },
  data() {
    return {
      errors: {},
      sites: []
    }
  },
  async created() {
    const siteJson = await window.localStorage.getItem("sites");

    this.sites = JSON.parse(siteJson);
    if (this.sites.length == 1) {
      this.choose(this.sites[0]);
    }
    
  },
  methods: {
    choose(site) {
      this.$store.dispatch("setSite", site);
      window.localStorage.setItem("site", site);
      this.$router.push('/station')
    },
    signOut(){
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang="scss">


form {
  border-radius: 0.5rem;
  max-width: 400px;
  padding: 2rem;
  margin: 0px auto;
  margin-top: 1rem;
  position: relative;
  background-color: var(--secondary-colour);
  font-size: 1em;
  .title{
    text-transform: capitalize;
  }
  
  img { width: 100%; }

  a{
    color: var(--text-colour);
    text-decoration: none;
  }

  //a:v
  button {
    margin-bottom: 1rem;
    margin-lefT: 0px !important;
    padding: 1rem;
    width: 100%;
    background-color: lavender;
    font-weight: bold;
    border-radius: 0.25rem;
    background-color: var(--action-colour);
    color: var(--primary-colour);
  }

  input, button { font-size: 1rem; border: none;   }
  input { font-size: 1rem; box-sizing: border-box; padding: 1em; width: 100%;    }

  p.or { text-align: center; }

  .btn-outline-info:hover { background-color: inherit; }
}

form div.error {
  display: block;
  margin-bottom: 1em;
  color: #ff8197;
}

form input {
  position: relative;
  margin-bottom: 1rem;
}

form input, form button {
  display: block;
}

.password {
  display: block;
  text-align: right;
  font-size: 0.8em;
  margin-top: 10px;
}

@media screen and (max-width: 840px) {
  form {
    border: none;
    padding: 2em;
    // max-width: none !important;

  }
}

</style>
