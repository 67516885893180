<template>
  <Unauthenticated>
    <div class="top-margin">
      <form v-if="!code" v-on:submit.prevent="login">
        <img src="/beamylogo_white.svg" />
        <h1>Point of Sale</h1>
        <input name="email" v-model="email" placeholder="Email Address" autocomplete="username" />
        <div class="error" v-if="errors && errors.email">Email Address {{ errors.email.join(", ") }}</div>

        <input type="password" name="current-password" v-model="password" placeholder="Password" />
        <div class="error" v-if="errors && errors.password">Password {{ errors.password.join(", ") }}</div>

        <div class="error" v-if="errors && errors.all">{{ errors.all }}</div>
        <button type="submit" value="Log In" class="primary w-100">Log in</button>

        <button @click.stop.prevent="code = !code" class="btn btn-primary btn-lg btn-dark">Log in using code</button>
        <p>If you haven't got an account with us, you can signup at bridge.beamy.nz</p>
      </form>
      <form v-if="code" v-on:submit.prevent="codeLogin" class="form-container">
        <img src="/beamylogo_white.svg" />
        <h1>Point of Sale</h1>
        <div class="digits form">
          <input
            v-for="(digit, idx) in digits"
            :key="idx"
            class="digit-input"
            type="text"
            inputmode="numeric"
            name="code"
            maxlength="1"
            v-model="digits[idx]"
            @input="handleInput(idx, $event)"
            @keydown="handleKeydown(idx, $event)"
            @paste="onPaste(event)"
            :ref="`digitInput${idx}`"
            :autofocus="idx === 0" />
        </div>
        <div class="footer">
          <button type="submit" class="btn btn-primary btn-lg">Login</button>
          <button @click.stop.prevent="code = !code" class="btn btn-primary btn-lg">Log in using credentials</button>
        </div>
      </form>
    </div>
    <div v-if="loading">
      <LoadingSpinner :spinnerMessage="'Retreiving Data...'"/>
    </div>
  </Unauthenticated>
</template>

<script>
import Unauthenticated from "@/components/_layouts/Unauthenticated";
import LoadingSpinner from "@/components/_layouts/LoadingSpinner";
import { api } from "@/helpers/api";
import { bagelSocket } from "@/helpers/socket";

export default {
  name: "Login",
  components: { Unauthenticated, LoadingSpinner },
  data() {
    return {
      errors: {},
      email: null,
      password: null,
      code: false,
      digits: ["", "", "", "", "", ""],
      loading: false,
    };
  },

  computed: {
    codeSubmitted() {
      return this.digits.join("");
    },
  },

  methods: {
    async codeLogin() {
      let result = await api.device_login(parseInt(this.codeSubmitted));
      window.localStorage.setItem("token", result.jwt_token);
      this.loading = true;
      if (result == "error") {
        this.errors = "Login Failed";
        this.loading = false;
        return;
      }

      this.$store.dispatch("setToken", result.jwt_token);

      const company = await api.get('/api/company/get_pos/"' + result.data.company + '"');
      window.localStorage.setItem("company", result.data.company);
      window.localStorage.setItem("sites", JSON.stringify(company.rows[0].value.sites));
      window.localStorage.setItem("stations", JSON.stringify(company.rows[0].value.stations));
      window.localStorage.setItem("theme", JSON.stringify(company.rows[0].value.theme));
      api.loadTheme(company.rows[0].value.theme);

      await bagelSocket.setUpChannel(this);

      window.localStorage.setItem("site", result.data.site);
      this.$store.dispatch("setSite", result.data.site);

      window.localStorage.setItem("station", result.data.station);
      this.$store.dispatch("setStation", result.data.station);


      this.$store.dispatch("getAllData");
      this.$router.push({ path: "/store" });
      this.loading = false;
    },

    handleInput(idx, event) {
      let current = event.target;
      if (this.digits[idx].length == 1 && idx < this.digits.length - 1) {
        current.nextElementSibling.focus();
      }
    },

    handleKeydown(idx, event) {
      let current = event.target;
      if (event.key == "Backspace" && this.digits[idx] == "" && idx > 0) {
        current.previousElementSibling.focus();
      }
    },

    async onPaste() {
      let paste = await navigator.clipboard.readText();
      let pasteArray = paste.split("");
      if (pasteArray.length !== 6) {
        return;
      }
      pasteArray.forEach((digit, idx) => {
        this.digits[idx] = digit;
      });
    },

    async login() {
      this.errors = {};
      try {
        let result = await api.login(this.email.trim(), this.password.trim());
        window.localStorage.setItem("token", result.jwt_token);
        this.loading = true;
        /* set the global JWT token */
        this.$store.dispatch("setToken", result.jwt_token);

        const company = await api.get('/api/company/get_pos/"' + result.company + '"');
        window.localStorage.setItem("company", result.company);
        window.localStorage.setItem("sites", JSON.stringify(company.rows[0].value.sites));
        window.localStorage.setItem("stations", JSON.stringify(company.rows[0].value.stations));
        window.localStorage.setItem("theme", JSON.stringify(company.rows[0].value.theme));
        api.loadTheme(company.rows[0].value.theme);
        this.$.appContext.config.globalProperties.token = result.jwt_token;

        this.$router.push({ path: "/choose" });
        this.loading = false;
      } catch (error) {
        Object.keys(error).map((code) => {
          this.errors[code] = [error[code]];
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
form {
  border-radius: 0.5rem;
  max-width: 400px;
  padding: 3rem;
  margin: 0px auto;
  margin-top: 0.5rem;
  position: relative;
  background-color: var(--secondary-colour);
  font-size: 1em;
  box-shadow: var(--box-shadow);
  img {
    width: 100%;
  }

  p {
    text-align: center;
  }

  a {
    color: var(--text-colour);
    text-decoration: none;
  }

  h1 {
    color: var(--action-colour);
    margin: 0 0 1rem 0;

  }

  .digits {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .digit-input {
      text-align: center;
      width: 3rem;
      height: 4rem;
      margin: 0.5rem;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    }
  }

  //a:v
  button {
    margin: 0.5rem;
    margin-left: 0px !important;
    padding: 1rem;
    width: 100%;
    background-color: lavender;
    font-weight: bolder;
    border-radius: 5px;
    background-color: var(--action-colour);
    color: var(--primary-colour);
    box-shadow: var(--box-shadow);
    &:active {
      box-shadow: none;
      scale: 0.99;
    }
  }

  .btn-dark {
    background-color: var(--primary-colour);
    color: var(--text-colour);
  }

  input,
  button {
    font-size: 1rem;
    border: none;
  }
  input {
    font-size: 1rem;
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
  }

  p.or {
    text-align: center;
  }

  .btn-outline-info:hover {
    background-color: inherit;
  }
}

form div.error {
  display: block;
  margin-bottom: 1em;
  color: #ff8197;
}

form input {
  position: relative;
  margin-bottom: 1rem;
}

form input,
form button {
  display: block;
}

.password {
  display: block;
  text-align: right;
  font-size: 0.8em;
  margin-top: 10px;
}

@media screen and (max-width: 840px) {
  form {
    border: none;
    padding: 2em;
    // max-width: none !important;
  }
}
</style>
