const state = {
    token: null
}

const mutations = {
    setToken(state, value) {
        state.token = value;
    }
}

const actions = {
    setToken({ commit }, payload) {
        commit("setToken", payload);
    }
}

const getters = {
    token: state => state.token
}

const tokenModule = {
    state,
    strict: true,
    getters,
    actions,
    mutations
}

export default tokenModule;
