<template>
  <Unauthenticated>
    <div class="top-margin">
      <form v-on:submit.prevent="login">
        <img src="/beamylogo_white.svg" />
        <div class="error" v-if="errors && errors.all">{{ errors.all }}</div>
        <h2>Choose Station:</h2>

        <div :key="station" v-for="station in stations">
          <button @click="choose(station)" type="submit" value="Log In" class="w-100">{{ station.name }}</button>
        </div>

        <a class="other" @click="signOut">Sign out</a>
      </form>
    </div>
  </Unauthenticated>
</template>

<script>
import Unauthenticated from "@/components/_layouts/Unauthenticated";
import { bagelSocket } from "@/helpers/socket";

export default {
  name: "ChooseStation",
  components: { Unauthenticated },
  data() {
    return {
      errors: {},
      stations: [],
    };
  },
  async created() {
    const stationJson = await window.localStorage.getItem("stations");
    const currSite = await localStorage.getItem("site");
    this.stations = JSON.parse(stationJson).filter((station) => station.site == currSite);
    if (this.stations.length == 1) {
      await bagelSocket.setUpChannel(this);
      await window.localStorage.setItem("station", this.stations[0].name);
      this.$router.push("/store");
    }

    //if (this.stations.length == 1) {
    //  this.choose(this.stations[0]);
    //}
  },
  methods: {
    async choose(station) {
      await window.localStorage.setItem("station", station.name);
      await bagelSocket.setUpChannel(this);
      setTimeout(this.chooseDelay, 500, station);
    },
    chooseDelay(station) {
      if (this.$store.getters.socketConnected) {
        this.$store.dispatch("setStation", station.name);
        this.$store.dispatch("getAllData");
        this.$router.push("/store");
      } else {
        this.$notify({ text: "Station logged in, please choose/create another.", type: "warn" });
      }
    },
    signOut() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
form {
  border-radius: 0.5rem;
  max-width: 400px;
  padding: 2rem;
  margin: 0px auto;
  margin-top: 1rem;
  position: relative;
  background-color: var(--secondary-colour);
  font-size: 1em;
  img {
    width: 100%;
  }

  a {
    color: var(--text-colour);
    text-decoration: none;
  }

  //a:v
  button {
    margin-bottom: 1rem;
    margin-left: 0px !important;
    padding: 1rem;
    width: 100%;
    background-color: lavender;
    font-weight: bold;
    border-radius: 0.25rem;
    background-color: var(--action-colour);
    color: var(--primary-colour);
  }

  input,
  button {
    font-size: 1rem;
    border: none;
  }
  input {
    font-size: 1rem;
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
  }

  p.or {
    text-align: center;
  }

  .btn-outline-info:hover {
    background-color: inherit;
  }
}

form div.error {
  display: block;
  margin-bottom: 1em;
  color: #ff8197;
}

form input {
  position: relative;
  margin-bottom: 1rem;
}

form input,
form button {
  display: block;
}

.password {
  display: block;
  text-align: right;
  font-size: 0.8em;
  margin-top: 10px;
}

@media screen and (max-width: 840px) {
  form {
    border: none;
    padding: 2em;
    // max-width: none !important;
  }
}
</style>
